import * as React from 'react';
import { Layout } from '../../components/layout';
import { Seo } from '../../components/seo';
import * as Styles from '../../styles/pages/_login.module.scss';
import * as VoteStyles from '../../styles/pages/_vote.module.scss'
import * as CommonStyles from '../../styles/global/_common.module.scss';
import { BreadCrumb } from '../../components/breadcrumb';
import { BackGround } from '../../components/background';
import {Link, navigate, useIntl} from "gatsby-plugin-react-intl"
import defaultUserIcon from '../../images/icon_user_default.jpg'
import {countryCode} from "../../../static/assets/json/iso3166";
import * as LoadingElementStyles from "../../styles/components/_loading_element.module.scss";
import LoadingElement from "../../components/LoadingElement";
import {postAPI} from "../../services/fetch";
import {setStateCode, userSettingSetup} from "../../services/users";

const UserSettingPage = ({ location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  const [isLoading, setIsLoading] = React.useState(false)
  const [countries, setCountries] = React.useState(countryCode)
  const [isEnglish, setIsEnglish] = React.useState(intl.locale !== 'ja')

  const [dispSuccess, setDispSuccess] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState(t('users.setting.updateSuccess'))
  const [dispFailure, setDispFailure] = React.useState(false)
  const [failureMessage, setFailureMessage] = React.useState(t('users.setting.updateFailed'))

  const [isInvalidName, setIsInvalidName] = React.useState(false)
  const [isValidName, setIsValidName] = React.useState(false)
  const [nameErrorMessage, setNameErrorMessage] = React.useState(t('users.setting.existNickname'))

  const displaySuccessBar = (message) => {
    setSuccessMessage(message)
    setDispSuccess(true)
    setTimeout(() => {
      setDispSuccess(false)
    }, 3000)
  }
  const displayFailureBar = (message) => {
    setFailureMessage(message)
    setDispFailure(true)
    setTimeout(() => {
      setDispFailure(false)
    }, 3000)
  }

  const onChangeIcon = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    const image = document.getElementById("icon_image")
    reader.addEventListener("load", function () {
      image.src = reader.result
    }, false)
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const onChangeCountry = async (e) => {
    // console.log('#########', e.target.value)
    await setStateCode(e.target.value)
  }

  const onClickUpdate = async () => {
    const nicknameInput = document.getElementById('user_profile_name')
    const nickname = nicknameInput.value
    if (nickname === '') {
      setIsInvalidName(true)
      setNameErrorMessage(t('users.setting.require'))
      displayFailureBar(t('users.setting.updateFailed'))
      return
    }
    setIsLoading(true)

    const countrySelect = document.getElementById('user_profile_country_code')
    const countryCode = countrySelect.options[countrySelect.selectedIndex].value

    const stateSelect = document.getElementById('user_profile_state_code')
    const stateCode = stateSelect.options.length > 0 ? stateSelect.options[stateSelect.selectedIndex].value : null

    const localeSelect = document.getElementById('user_profile_locale')
    const localeCode = localeSelect.options.length > 0 ? localeSelect.options[localeSelect.selectedIndex].value : null

    const timezoneSelect = document.getElementById('user_profile_time_zone')
    const timezoneCode = timezoneSelect.options[timezoneSelect.selectedIndex].value

    const iconFile = document.getElementById('artist_icon')
    const filename = iconFile.value

    const deleteCheck = document.getElementById('artist_remove_icon')
    const isDelete = deleteCheck.checked

    // console.log('###', { nickname, countryCode, stateCode, localeCode, timezoneCode, filename, isDelete })
    const params = new FormData()
    params.append('name', nickname)
    if (countryCode) params.append('country_code', countryCode)
    if (stateCode) params.append('state_code', stateCode)
    if (localeCode) params.append('locale', localeCode)
    if (timezoneCode) params.append('time_zone', timezoneCode)
    if (filename) params.append('icon_image', iconFile.files[0])
    if (isDelete) params.append('icon_initialize', 1)

    await postAPI({ url: '/api/user/profile/edit', params })
      .then((response) => {
        if (response.data.result === 0) {
          displaySuccessBar(t('users.setting.updateSuccess'))
          if (deleteCheck.checked) {
            const icon = document.getElementById('icon_image')
            icon.src = defaultUserIcon
            deleteCheck.checked = false
          }
          iconFile.value = ''
        } else if (response.data.result === 1) {
          displayFailureBar(t('users.setting.existNickname'))
        } else {
          displayFailureBar(t('users.setting.updateFailed'))
        }
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        displayFailureBar(t('users.setting.updateFailed'))
        setIsLoading(false)
      });
  }

  return (
    <Layout location={location}>
      <div id={'loading-element'} className={isLoading ? '' : LoadingElementStyles.hidden}>
        <LoadingElement />
      </div>
      <Seo title={t('users.setting.seo.title')} description={t('users.setting.seo.description')} />
      <div className={VoteStyles.vote_bar}>
        <p id={'vote-success'} className={(dispSuccess ? VoteStyles.disp : '')}>{successMessage}</p>
      </div>
      <div className={VoteStyles.vote_bar_ng}>
        <p id={'vote-failure'} className={(dispFailure ? VoteStyles.disp : '')}>{failureMessage}</p>
      </div>
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
        ]}
        currentPage={t('users.setting.breadcrumbTitle')}
      />
      <BackGround>
        <section className={CommonStyles.wrapper}>
          <div className={CommonStyles.container}>
            <div id="users" className={Styles.users}>
              <h1>{t('users.setting.title')}</h1>
              <section>
                <h2>{t('users.setting.profile')}</h2>
                <div className={Styles.mg_b1}>
                  <label className={Styles.icon_required} htmlFor="user_profile_name">{t('users.setting.nickname')}</label>
                  <input
                    className={(isValidName ? Styles.is_valid : (isInvalidName ? Styles.is_invalid : ''))}
                    type="text"
                    name="user_profile[name]"
                    id="user_profile_name"
                    // value={nickname}
                    // onChange={(e) => checkNickName(e.target.value)}
                    required
                  />
                    {/*// <!-- ↑通常時はclassなし。登録に問題がない状態のときにclass.is-validを追加 -->*/}
                    {/*// <!-- ↑登録に問題がある状態のときにclass.is-invalidを追加 -->*/}
                    {/*// <!-- エラー文言ここから// -->*/}
                  {isInvalidName && (
                    <small>
                      <span className={Styles.color_error}>{nameErrorMessage}</span>
                    </small>
                  )}
                  {/*// <!-- //エラー文言ここまで -->*/}
                  <small>{t('users.setting.nicknameText')}</small>
                </div>
                <div className={Styles.mg_b1}>
                  <label htmlFor="user_profile_country_code">
                    {t('users.setting.country')}
                  </label>
                  <select
                    data-locale="ja"
                    name="user_profile[country_code]"
                    id="user_profile_country_code"
                    onChange={onChangeCountry}
                  >
                  </select>
                </div>
                <div className={Styles.mg_b1}>
                  <label htmlFor="user_profile_state_code">
                    {t('users.setting.area')}
                  </label>
                  <select
                    name="user_profile[state_code]"
                    id="user_profile_state_code"
                  >
                  </select>
                </div>
                <div className={Styles.mg_b1}>
                  <label htmlFor="user_profile_locale">
                    {t('users.setting.firstLanguage')}
                  </label>
                  <select
                    className={`${Styles.form_control} ${Styles.is_valid} ${Styles.enum} ${Styles.optional}`}
                    name="user_profile[locale]"
                    id="user_profile_locale"
                    defaultValue="ja"
                  >
                    <option value="ja">日本語</option>
                    <option value="en">English</option>
                    <option value="ko">한국</option>
                    <option value="zh-CN">中文(簡体)</option>
                    <option value="zh-TW">中文(繁体)</option>
                  </select>
                  <small>{t('users.setting.languageText')}</small>
                </div>
                <div className={Styles.mg_b1}>
                  <label htmlFor="user_profile_time_zone">
                    {t('users.setting.timezone')}
                  </label>
                  <select
                    name="user_profile[time_zone]"
                    id="user_profile_time_zone"
                    defaultValue="Tokyo"
                  >
                    <option value="Osaka">(GMT+09:00) Osaka</option>
                    <option value="Sapporo">(GMT+09:00) Sapporo</option>
                    <option value="Tokyo">(GMT+09:00) Tokyo</option>
                    <option value="" disabled="disabled">-------------</option>
                    <option value="International Date Line West">(GMT-12:00) International Date Line West</option>
                    <option value="American Samoa">(GMT-11:00) American Samoa</option>
                    <option value="Midway Island">(GMT-11:00) Midway Island</option>
                    <option value="Hawaii">(GMT-10:00) Hawaii</option>
                    <option value="Alaska">(GMT-09:00) Alaska</option>
                    <option value="Pacific Time (US &amp; Canada)">(GMT-08:00) Pacific Time (US &amp; Canada)</option>
                    <option value="Tijuana">(GMT-08:00) Tijuana</option>
                    <option value="Arizona">(GMT-07:00) Arizona</option>
                    <option value="Chihuahua">(GMT-07:00) Chihuahua</option>
                    <option value="Mazatlan">(GMT-07:00) Mazatlan</option>
                    <option value="Mountain Time (US &amp; Canada)">(GMT-07:00) Mountain Time (US &amp; Canada)</option>
                    <option value="Central America">(GMT-06:00) Central America</option>
                    <option value="Central Time (US &amp; Canada)">(GMT-06:00) Central Time (US &amp; Canada)</option>
                    <option value="Guadalajara">(GMT-06:00) Guadalajara</option>
                    <option value="Mexico City">(GMT-06:00) Mexico City</option>
                    <option value="Monterrey">(GMT-06:00) Monterrey</option>
                    <option value="Saskatchewan">(GMT-06:00) Saskatchewan</option>
                    <option value="Bogota">(GMT-05:00) Bogota</option>
                    <option value="Eastern Time (US &amp; Canada)">(GMT-05:00) Eastern Time (US &amp; Canada)</option>
                    <option value="Indiana (East)">(GMT-05:00) Indiana (East)</option>
                    <option value="Lima">(GMT-05:00) Lima</option>
                    <option value="Quito">(GMT-05:00) Quito</option>
                    <option value="Atlantic Time (Canada)">(GMT-04:00) Atlantic Time (Canada)</option>
                    <option value="Caracas">(GMT-04:00) Caracas</option>
                    <option value="Georgetown">(GMT-04:00) Georgetown</option>
                    <option value="La Paz">(GMT-04:00) La Paz</option>
                    <option value="Puerto Rico">(GMT-04:00) Puerto Rico</option>
                    <option value="Santiago">(GMT-04:00) Santiago</option>
                    <option value="Newfoundland">(GMT-03:30) Newfoundland</option>
                    <option value="Brasilia">(GMT-03:00) Brasilia</option>
                    <option value="Buenos Aires">(GMT-03:00) Buenos Aires</option>
                    <option value="Greenland">(GMT-03:00) Greenland</option>
                    <option value="Montevideo">(GMT-03:00) Montevideo</option>
                    <option value="Mid-Atlantic">(GMT-02:00) Mid-Atlantic</option>
                    <option value="Azores">(GMT-01:00) Azores</option>
                    <option value="Cape Verde Is.">(GMT-01:00) Cape Verde Is.</option>
                    <option value="Casablanca">(GMT+00:00) Casablanca</option>
                    <option value="Dublin">(GMT+00:00) Dublin</option>
                    <option value="Edinburgh">(GMT+00:00) Edinburgh</option>
                    <option value="Lisbon">(GMT+00:00) Lisbon</option>
                    <option value="London">(GMT+00:00) London</option>
                    <option value="Monrovia">(GMT+00:00) Monrovia</option>
                    <option value="UTC">(GMT+00:00) UTC</option>
                    <option value="Amsterdam">(GMT+01:00) Amsterdam</option>
                    <option value="Belgrade">(GMT+01:00) Belgrade</option>
                    <option value="Berlin">(GMT+01:00) Berlin</option>
                    <option value="Bern">(GMT+01:00) Bern</option>
                    <option value="Bratislava">(GMT+01:00) Bratislava</option>
                    <option value="Brussels">(GMT+01:00) Brussels</option>
                    <option value="Budapest">(GMT+01:00) Budapest</option>
                    <option value="Copenhagen">(GMT+01:00) Copenhagen</option>
                    <option value="Ljubljana">(GMT+01:00) Ljubljana</option>
                    <option value="Madrid">(GMT+01:00) Madrid</option>
                    <option value="Paris">(GMT+01:00) Paris</option>
                    <option value="Prague">(GMT+01:00) Prague</option>
                    <option value="Rome">(GMT+01:00) Rome</option>
                    <option value="Sarajevo">(GMT+01:00) Sarajevo</option>
                    <option value="Skopje">(GMT+01:00) Skopje</option>
                    <option value="Stockholm">(GMT+01:00) Stockholm</option>
                    <option value="Vienna">(GMT+01:00) Vienna</option>
                    <option value="Warsaw">(GMT+01:00) Warsaw</option>
                    <option value="West Central Africa">(GMT+01:00) West Central Africa</option>
                    <option value="Zagreb">(GMT+01:00) Zagreb</option>
                    <option value="Zurich">(GMT+01:00) Zurich</option>
                    <option value="Athens">(GMT+02:00) Athens</option>
                    <option value="Bucharest">(GMT+02:00) Bucharest</option>
                    <option value="Cairo">(GMT+02:00) Cairo</option>
                    <option value="Harare">(GMT+02:00) Harare</option>
                    <option value="Helsinki">(GMT+02:00) Helsinki</option>
                    <option value="Jerusalem">(GMT+02:00) Jerusalem</option>
                    <option value="Kaliningrad">(GMT+02:00) Kaliningrad</option>
                    <option value="Kyiv">(GMT+02:00) Kyiv</option>
                    <option value="Pretoria">(GMT+02:00) Pretoria</option>
                    <option value="Riga">(GMT+02:00) Riga</option>
                    <option value="Sofia">(GMT+02:00) Sofia</option>
                    <option value="Tallinn">(GMT+02:00) Tallinn</option>
                    <option value="Vilnius">(GMT+02:00) Vilnius</option>
                    <option value="Baghdad">(GMT+03:00) Baghdad</option>
                    <option value="Istanbul">(GMT+03:00) Istanbul</option>
                    <option value="Kuwait">(GMT+03:00) Kuwait</option>
                    <option value="Minsk">(GMT+03:00) Minsk</option>
                    <option value="Moscow">(GMT+03:00) Moscow</option>
                    <option value="Nairobi">(GMT+03:00) Nairobi</option>
                    <option value="Riyadh">(GMT+03:00) Riyadh</option>
                    <option value="St. Petersburg">(GMT+03:00) St. Petersburg</option>
                    <option value="Tehran">(GMT+03:30) Tehran</option>
                    <option value="Abu Dhabi">(GMT+04:00) Abu Dhabi</option>
                    <option value="Baku">(GMT+04:00) Baku</option>
                    <option value="Muscat">(GMT+04:00) Muscat</option>
                    <option value="Samara">(GMT+04:00) Samara</option>
                    <option value="Tbilisi">(GMT+04:00) Tbilisi</option>
                    <option value="Volgograd">(GMT+04:00) Volgograd</option>
                    <option value="Yerevan">(GMT+04:00) Yerevan</option>
                    <option value="Kabul">(GMT+04:30) Kabul</option>
                    <option value="Ekaterinburg">(GMT+05:00) Ekaterinburg</option>
                    <option value="Islamabad">(GMT+05:00) Islamabad</option>
                    <option value="Karachi">(GMT+05:00) Karachi</option>
                    <option value="Tashkent">(GMT+05:00) Tashkent</option>
                    <option value="Chennai">(GMT+05:30) Chennai</option>
                    <option value="Kolkata">(GMT+05:30) Kolkata</option>
                    <option value="Mumbai">(GMT+05:30) Mumbai</option>
                    <option value="New Delhi">(GMT+05:30) New Delhi</option>
                    <option value="Sri Jayawardenepura">(GMT+05:30) Sri Jayawardenepura</option>
                    <option value="Kathmandu">(GMT+05:45) Kathmandu</option>
                    <option value="Almaty">(GMT+06:00) Almaty</option>
                    <option value="Astana">(GMT+06:00) Astana</option>
                    <option value="Dhaka">(GMT+06:00) Dhaka</option>
                    <option value="Urumqi">(GMT+06:00) Urumqi</option>
                    <option value="Rangoon">(GMT+06:30) Rangoon</option>
                    <option value="Bangkok">(GMT+07:00) Bangkok</option>
                    <option value="Hanoi">(GMT+07:00) Hanoi</option>
                    <option value="Jakarta">(GMT+07:00) Jakarta</option>
                    <option value="Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                    <option value="Novosibirsk">(GMT+07:00) Novosibirsk</option>
                    <option value="Beijing">(GMT+08:00) Beijing</option>
                    <option value="Chongqing">(GMT+08:00) Chongqing</option>
                    <option value="Hong Kong">(GMT+08:00) Hong Kong</option>
                    <option value="Irkutsk">(GMT+08:00) Irkutsk</option>
                    <option value="Kuala Lumpur">(GMT+08:00) Kuala Lumpur</option>
                    <option value="Perth">(GMT+08:00) Perth</option>
                    <option value="Singapore">(GMT+08:00) Singapore</option>
                    <option value="Taipei">(GMT+08:00) Taipei</option>
                    <option value="Ulaanbaatar">(GMT+08:00) Ulaanbaatar</option>
                    <option value="Seoul">(GMT+09:00) Seoul</option>
                    <option value="Yakutsk">(GMT+09:00) Yakutsk</option>
                    <option value="Adelaide">(GMT+09:30) Adelaide</option>
                    <option value="Darwin">(GMT+09:30) Darwin</option>
                    <option value="Brisbane">(GMT+10:00) Brisbane</option>
                    <option value="Canberra">(GMT+10:00) Canberra</option>
                    <option value="Guam">(GMT+10:00) Guam</option>
                    <option value="Hobart">(GMT+10:00) Hobart</option>
                    <option value="Melbourne">(GMT+10:00) Melbourne</option>
                    <option value="Port Moresby">(GMT+10:00) Port Moresby</option>
                    <option value="Sydney">(GMT+10:00) Sydney</option>
                    <option value="Vladivostok">(GMT+10:00) Vladivostok</option>
                    <option value="Magadan">(GMT+11:00) Magadan</option>
                    <option value="New Caledonia">(GMT+11:00) New Caledonia</option>
                    <option value="Solomon Is.">(GMT+11:00) Solomon Is.</option>
                    <option value="Srednekolymsk">(GMT+11:00) Srednekolymsk</option>
                    <option value="Auckland">(GMT+12:00) Auckland</option>
                    <option value="Fiji">(GMT+12:00) Fiji</option>
                    <option value="Kamchatka">(GMT+12:00) Kamchatka</option>
                    <option value="Marshall Is.">(GMT+12:00) Marshall Is.</option>
                    <option value="Wellington">(GMT+12:00) Wellington</option>
                    <option value="Chatham Is.">(GMT+12:45) Chatham Is.</option>
                    <option value="Nuku'alofa">(GMT+13:00) Nuku'alofa</option>
                    <option value="Samoa">(GMT+13:00) Samoa</option>
                    <option value="Tokelau Is.">(GMT+13:00) Tokelau Is.</option>
                  </select>
                </div>
              </section>

              <section>
                <h2>{t('users.setting.iconPicture')}</h2>
                <div className={Styles.flex_container}>
                  <div className={`${Styles.flex_item} ${Styles.item_30} ${Styles.for_sp} ${Styles.no_mg}`}>
                    <div className={`${Styles.image_wrapper} ${Styles.text_center} ${Styles.for_sp}`}>
                      <img
                        className={Styles.img_thumb}
                        id="icon_image"
                        src={defaultUserIcon}
                      />
                    </div>
                    <div className={`${Styles.text_center} ${Styles.mg_t1}`}>
                      <input type="checkbox" name="artist[remove_icon]" id="artist_remove_icon" />
                      <label htmlFor="artist_remove_icon">{t('users.setting.deletePicture')}</label>
                    </div>
                  </div>
                  <div className={`${Styles.flex_item} ${Styles.item_70} ${Styles.for_sp}`}>
                    <input type="file" name="artist[icon]" id="artist_icon" onChange={onChangeIcon} />
                    <div>
                      {t('users.setting.pictureText1')}<br/>
                      {t('users.setting.pictureText2')}
                    </div>
                  </div>
                </div>
              </section>
              <div className={Styles.text_center}>
                <button type="button" className={`${Styles.btn_primary} ${Styles.btn_setting}`} onClick={onClickUpdate}>
                  {t('users.setting.update')}
                </button>
                {/*<div className={`${Styles.btn_common} ${Styles.btn_setting}`}>*/}
                {/*  {t('users.setting.cancel')}*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export default UserSettingPage;
